import { EnumFiscalResidence } from '@blank/api'
import {
  CountryConfig,
  EnumCompanySearchMode,
  EnumHomeCountryCode,
} from '../../types'

export const BLANK_FR_COUNTRY_CONFIG: CountryConfig = {
  companyIdentificationNumberLength: 9,
  shouldChooseDomiciliationOnSignup: false,
  companySelectionSearchMode: EnumCompanySearchMode.AUTOCOMPLETE,
  isBeta: false,
  canAddEnterpriseMandate: true,
  shouldDisplayUserFeedbackButton: false,
  canAddSponsorshipVoucherOnSignup: true,
  increaseTransferLimitTypeformId: 'C9OWESfP',
  feedbackTypeformId: '',
  accountClosingTypeformId: 'YdO8BtiT',
  shouldDisplayDsp2AnnouncementModal: true,
  shouldDisplayAdditionalTouOnSelectOffer: false,
  fiscalResidenceOptions: [EnumFiscalResidence.FRA, EnumFiscalResidence.USA],
  homeCountryCode: EnumHomeCountryCode.FRA,
  invoicing: {
    canCreateEstimateDocument: true,
    canAddFiscalTaxesToDocument: false,
    canAddFiscalStampToDocument: false,
    shouldSelectVATExonerationCode: false,
  },
  shouldDisplayAverageBalanceDocuments: false,
  shouldBuildMicroEnterpriseCreationURLWithParams: false,
  canUseExtendedSupport: true,
  supportPhoneNumber: '09 80 80 24 84',
  canDepositCheck: true,
  shouldCustomizeCardFraudInsuranceRedirectionUrl: true,
}
