import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { EnumCommonTrackingEvents } from '../lib/amplitude/events'

export const useTrackPageView = () => {
  const router = useRouter()
  const trackEvent = useTrackEvent()

  const handleRouteChange = useCallback(
    (path: string) => {
      trackEvent({
        eventType: EnumCommonTrackingEvents.PAGEVIEW,
        eventProperties: {
          path,
          url: window.location.href,
        },
      })
    },
    [trackEvent]
  )

  // Handle navigation tracking while router path changes
  useEffect(() => {
    handleRouteChange(router.asPath)
  }, [handleRouteChange, router.asPath])
}
