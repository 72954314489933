import { mergeClasses } from '@blank/utilities'
import { IconArrowLeft, IconCross } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

interface Props {
  headerTitle?: string
  onBack?: () => void
  onClose?: () => void
  scrollTop: number
}

export const ModalHeader = ({
  headerTitle,
  onBack,
  onClose,
  scrollTop,
}: Props) => (
  <div
    className={mergeClasses(
      'relative flex min-h-16 w-full shrink-0 items-center justify-center px-14 pb-4 pt-5',
      {
        'border-b border-info-100': scrollTop > 0,
      }
    )}
  >
    {onBack && (
      <Button
        type="button"
        variant="none"
        onClick={onBack}
        className="!absolute left-4 top-4 h-8 w-8"
        data-testid="modal-header-back-button"
        icon={<IconArrowLeft />}
      />
    )}

    {headerTitle && <h3>{headerTitle}</h3>}
    {onClose && (
      <Button
        type="button"
        variant="none"
        onClick={onClose}
        className="!absolute right-4 top-4 h-8 w-8"
        data-testid="modal-header-close-button"
        icon={<IconCross />}
      />
    )}
  </div>
)
