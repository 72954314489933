import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { useSupportContext } from 'modules/common/hooks/useSupportContext'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import { CopyText } from '../CopyText/CopyText'
import { Link } from '../Link/Link'
import { IconEmailRead, IconPhone } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

interface Props {
  faqURL?: string
  emailSupport?: string
  phoneSupport?: string
}

export const SupportModalContent = ({
  faqURL,
  emailSupport,
  phoneSupport,
}: Props) => {
  const { t } = useTranslation('common-components')

  const isSupportAvailable = !!emailSupport || !!phoneSupport
  const supportContext = useSupportContext()
  const { canUseExtendedSupport } = useCountryConfig()

  return (
    <div className="self-center text-left">
      {faqURL && (
        <>
          <p className="pb-2 font-semibold uppercase text-info-400">
            {t('supportModal.faqTitle')}
          </p>
          <p className="pb-4">{t('supportModal.faqText')}</p>

          <Button
            variant="primary"
            color="default"
            className="mb-8 w-full min-w-full sm:min-w-0 sm:px-24"
            href={faqURL}
            {...externalLinkProps}
          >
            {t('supportModal.button')}
          </Button>
        </>
      )}
      {isSupportAvailable && (
        <>
          <p className="pb-2 font-semibold uppercase text-info-400">
            {t('supportModal.helpTitle')}
          </p>
          <p className="pb-8">{t('supportModal.helpText')}</p>

          <div className="divide-y divide-info-200 rounded-2xl border border-info-200 px-5">
            {!!phoneSupport && (
              <div className="flex h-20 flex-row items-center py-3">
                <IconPhone className="rounded-lg bg-primary-90 text-primary" />
                <div className="flex flex-col pl-5">
                  <p className="font-bold">{phoneSupport}</p>
                  <p className="text-sm text-info-400">
                    {t('supportModal.phoneDetails', {
                      context: supportContext,
                    })}
                  </p>
                </div>
              </div>
            )}

            {!!emailSupport && (
              <div className="flex h-20 flex-row items-center py-3">
                <IconEmailRead className="rounded-lg bg-primary-90 text-primary" />
                <Link
                  href={`mailto:${emailSupport}`}
                  className="inline-flex flex-1 flex-col pl-5 pr-6"
                >
                  <span className="font-bold">{emailSupport}</span>
                  {canUseExtendedSupport && (
                    <span className="whitespace-pre-wrap text-sm text-info-400">
                      {t('supportModal.emailDetails', {
                        context: supportContext,
                      })}
                    </span>
                  )}
                </Link>
                <CopyText textToCopy={emailSupport} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
