import { EnumFiscalResidence } from '@blank/api'
import { EnumUserLocale } from 'modules/common/types/locale'

export enum EnumCompanySearchMode {
  INPUT = 'input',
  AUTOCOMPLETE = 'autocomplete',
}

export enum EnumHomeCountryCode {
  FRA = 'FRA',
  ITA = 'ITA',
}

export interface CountryConfig {
  companyIdentificationNumberLength: number
  shouldChooseDomiciliationOnSignup: boolean
  companySelectionSearchMode: EnumCompanySearchMode
  isBeta: boolean
  canAddEnterpriseMandate: boolean
  shouldDisplayUserFeedbackButton: boolean
  canAddSponsorshipVoucherOnSignup: boolean
  increaseTransferLimitTypeformId: string
  feedbackTypeformId: string
  accountClosingTypeformId: string
  shouldDisplayDsp2AnnouncementModal: boolean
  shouldDisplayAdditionalTouOnSelectOffer: boolean
  fiscalResidenceOptions: EnumFiscalResidence[]
  homeCountryCode: EnumHomeCountryCode
  invoicing: {
    canCreateEstimateDocument: boolean
    canAddFiscalTaxesToDocument: boolean
    canAddFiscalStampToDocument: boolean
    shouldSelectVATExonerationCode: boolean
  }
  shouldDisplayAverageBalanceDocuments: boolean
  shouldBuildMicroEnterpriseCreationURLWithParams: boolean
  canUseExtendedSupport: boolean
  supportPhoneNumber: string
  canDepositCheck: boolean
  shouldCustomizeCardFraudInsuranceRedirectionUrl: boolean
}

export interface ConfigType {
  internalBankName: string
  locales: EnumUserLocale[]
  hasSubBanks: boolean
  signupConfig: {
    shouldDisplayCommercialSolicitationOptIn: boolean
    shouldDisplayOperatedBy: boolean
    canAccessManagementOffer: boolean
    canAccessQuickSignup: boolean
  }
  defaultPublicOfferCount: number
  caravelAffiliationCode: string
}

export type LocalizedCountryConfig = Partial<
  Record<EnumUserLocale, CountryConfig>
> & {
  [EnumUserLocale.FR]: CountryConfig
}
