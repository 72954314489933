import { SupportModalContent } from 'modules/common/components/SupportModalContent/SupportModalContent'
import { SupportModalContentSkeleton } from 'modules/common/components/SupportModalContent/SupportModalContentSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useSupportTouchpoints } from '../../hooks/useSupportTouchpoints'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'

const useSupportModal = (modalOptions?: Partial<ContentModalProps>) => {
  const { i18n, t } = useTranslation('common-components')

  const { faqURL, emailSupport, phoneSupport, isLoading } =
    useSupportTouchpoints()

  const content = isLoading ? (
    <SupportModalContentSkeleton />
  ) : (
    <SupportModalContent
      faqURL={faqURL}
      emailSupport={emailSupport}
      phoneSupport={phoneSupport}
    />
  )

  return useContentModal({
    width: 'medium',
    headerTitle: t('supportModal.title'),
    content,
    i18n,
    ...modalOptions,
  })
}

export default useSupportModal
