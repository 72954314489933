import { EnumFiscalResidence } from '@blank/api'
import {
  CountryConfig,
  EnumCompanySearchMode,
  EnumHomeCountryCode,
} from '../../types'

export const LCL_FR_COUNTRY_CONFIG: CountryConfig = {
  companyIdentificationNumberLength: 9,
  shouldChooseDomiciliationOnSignup: false,
  companySelectionSearchMode: EnumCompanySearchMode.AUTOCOMPLETE,
  isBeta: false,
  canAddEnterpriseMandate: true,
  shouldDisplayUserFeedbackButton: false,
  canAddSponsorshipVoucherOnSignup: false,
  increaseTransferLimitTypeformId: 'F1ZNqG4K',
  feedbackTypeformId: '',
  accountClosingTypeformId: 'ug1khzPq',
  shouldDisplayDsp2AnnouncementModal: true,
  shouldDisplayAdditionalTouOnSelectOffer: false,
  fiscalResidenceOptions: [EnumFiscalResidence.FRA, EnumFiscalResidence.USA],
  homeCountryCode: EnumHomeCountryCode.FRA,
  invoicing: {
    canCreateEstimateDocument: true,
    canAddFiscalTaxesToDocument: false,
    canAddFiscalStampToDocument: false,
    shouldSelectVATExonerationCode: false,
  },
  shouldDisplayAverageBalanceDocuments: false,
  shouldBuildMicroEnterpriseCreationURLWithParams: true,
  canUseExtendedSupport: false,
  supportPhoneNumber: '',
  canDepositCheck: false,
  shouldCustomizeCardFraudInsuranceRedirectionUrl: false,
}
