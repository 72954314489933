import { useLocaleRedirection } from 'modules/auth/hooks/useLocaleRedirection'
import { useRequiredAuthDataQueries } from 'modules/auth/hooks/useRequiredAuthDataQueries'
import { ErrorReload } from 'modules/common/components/ErrorReload/ErrorReload'
import { useIsMounted } from 'modules/common/hooks/useIsMounted'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect } from 'react'
import { FullPageLoader } from '../FullPageLoader/FullPageLoader'
import { RedirectAuthenticatedUserWrapper } from '../RedirectAuthenticatedUserWrapper/RedirectAuthenticatedUserWrapper'

export const AuthenticatedPageGuard = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('common-components')
  const isMounted = useIsMounted()
  const router = useRouter()

  useLocaleRedirection()

  const { isFetchError, isFetchLoading, isUserAuthenticated, errorRefetch } =
    useRequiredAuthDataQueries()

  useEffect(() => {
    if (!isUserAuthenticated) {
      router.replace({
        pathname: EnumRoutes.LOGIN,
        query: { redirectURL: router.asPath },
      })
    }
  }, [router, isUserAuthenticated])

  if (isFetchError) {
    return (
      <ErrorReload
        className="min-h-screen"
        title={t('authenticatedGuard.error.title')}
        firstDescription={t('authenticatedGuard.error.firstDescription')}
        secondDescription={t('authenticatedGuard.error.secondDescription')}
        onReload={errorRefetch}
      />
    )
  } else if (!isMounted || !isUserAuthenticated || isFetchLoading) {
    return <FullPageLoader />
  } else {
    return (
      <RedirectAuthenticatedUserWrapper>
        {children}
      </RedirectAuthenticatedUserWrapper>
    )
  }
}
