import { EnumFiscalResidence } from '@blank/api'
import {
  CountryConfig,
  EnumCompanySearchMode,
  EnumHomeCountryCode,
} from '../../types'

export const BLANK_IT_COUNTRY_CONFIG: CountryConfig = {
  companyIdentificationNumberLength: 11,
  shouldChooseDomiciliationOnSignup: true,
  companySelectionSearchMode: EnumCompanySearchMode.AUTOCOMPLETE,
  isBeta: true,
  canAddEnterpriseMandate: true,
  shouldDisplayUserFeedbackButton: true,
  canAddSponsorshipVoucherOnSignup: false,
  increaseTransferLimitTypeformId: 'v3ZysnUs',
  feedbackTypeformId: 'W7LXQDri',
  accountClosingTypeformId: 'kakQJ2BN',
  shouldDisplayDsp2AnnouncementModal: false,
  shouldDisplayAdditionalTouOnSelectOffer: true,
  fiscalResidenceOptions: [EnumFiscalResidence.ITA, EnumFiscalResidence.USA],
  homeCountryCode: EnumHomeCountryCode.ITA,
  invoicing: {
    canCreateEstimateDocument: false,
    canAddFiscalTaxesToDocument: true,
    canAddFiscalStampToDocument: true,
    shouldSelectVATExonerationCode: true,
  },
  shouldDisplayAverageBalanceDocuments: true,
  shouldBuildMicroEnterpriseCreationURLWithParams: true,
  canUseExtendedSupport: false,
  supportPhoneNumber: '',
  canDepositCheck: false,
  shouldCustomizeCardFraudInsuranceRedirectionUrl: false,
}
