import IconAirplane from './airplane.svg'
import IconArrowDown from './arrow-down.svg'
import IconArrowLeft from './arrow-left.svg'
import IconArrowRight from './arrow-right.svg'
import IconBankAdd from './bank-add.svg'
import IconBankCheck from './bank-check.svg'
import IconBankUp from './bank-up.svg'
import IconBank from './bank.svg'
import IconBankingTicket from './banking-ticket.svg'
import IconBarcodeScanner from './barcode-scanner.svg'
import IconBlank from './blank.svg'
import IconBriefcase from './briefcase.svg'
import IconCA from './ca.svg'
import IconCalculator from './calculator.svg'
import IconCalendarAdd from './calendar-add.svg'
import IconCalendarSchedule from './calendar-schedule.svg'
import IconCalendar from './calendar.svg'
import IconCamera from './camera.svg'
import IconCardAdd from './card-add.svg'
import IconCardDeposit from './card-deposit.svg'
import IconCardWarning from './card-warning.svg'
import IconCardWithdraw from './card-withdraw.svg'
import IconCard from './card.svg'
import IconCashDeposit from './cash-deposit.svg'
import IconCashGuarantee from './cash-guarantee.svg'
import IconCashTransferIn from './cash-transfer-in.svg'
import IconCashTransferOut from './cash-transfer-out.svg'
import IconCashWithdraw from './cash-withdraw.svg'
import IconChart from './chart.svg'
import IconCheckRounded from './check-rounded.svg'
import IconCheck from './check.svg'
import IconCheque from './cheque.svg'
import IconChevronLeft from './chevron-left.svg'
import IconChevronRight from './chevron-right.svg'
import IconCircle from './circle.svg'
import IconClaimOnline from './claim-online.svg'
import IconCoinStack from './coin-stack.svg'
import IconColorPicker from './color-picker.svg'
import IconConsultFaqs from './consult-faqs.svg'
import IconCopyText from './copy-text.svg'
import IconCrossRoundedFilled from './cross-rounded-filled.svg'
import IconCrossRounded from './cross-rounded.svg'
import IconCross from './cross.svg'
import IconDanger from './danger.svg'
import IconDocumentCalculator from './document-calculator.svg'
import IconDocumentEdit from './document-edit.svg'
import IconDocumentFlash from './document-flash.svg'
import IconDocumentText from './document-text.svg'
import IconDownloadArrow from './download-arrow.svg'
import IconEmailEdit from './email-edit.svg'
import IconEmailRead from './email-read.svg'
import IconEmail from './email.svg'
import IconExpandMore from './expand-more.svg'
import IconEyeClosed from './eye-closed.svg'
import IconEye from './eye.svg'
import IconFlagFrance from './flag-france.svg'
import IconFlagItaly from './flag-italy.svg'
import IconFlash from './flash.svg'
import IconForkKnife from './fork-knife.svg'
import IconGasStation from './gas-station.svg'
import IconGift from './gift.svg'
import IconGmail from './gmail.svg'
import IconHospital from './hospital.svg'
import IconHouse from './house.svg'
import IconHousing from './housing.svg'
import IconInformationSquare from './information-square.svg'
import IconInvoiceAdd from './invoice-add.svg'
import IconInvoice from './invoice.svg'
import IconJustice from './justice.svg'
import IconLCL from './lcl.svg'
import IconLightBulb from './light-bulb.svg'
import IconLocker from './locker.svg'
import IconLogOut from './logout.svg'
import IconMaterialWarranty from './material-warranty.svg'
import IconMenuCloseLeft from './menu-close-left.svg'
import IconMenuCloseRight from './menu-close-right.svg'
import IconMenu from './menu.svg'
import IconMinus from './minus.svg'
import IconMobileDevice from './mobile-device.svg'
import IconNotification from './notification.svg'
import IconOptions from './options.svg'
import IconOutlook from './outlook.svg'
import IconPenEdit from './pen-edit.svg'
import IconPen from './pen.svg'
import IconPending from './pending.svg'
import IconPhone from './phone.svg'
import IconPieChart from './pie-chart.svg'
import IconPlusRounded from './plus-rounded.svg'
import IconPlus from './plus.svg'
import IconProtectionShield from './protection-shield.svg'
import IconQuestionCircle from './question-circle.svg'
import IconQuestionSquare from './question-square.svg'
import IconRecurring from './recurring.svg'
import IconReload from './reload.svg'
import IconRIB from './rib.svg'
import IconRocket from './rocket.svg'
import IconRotate from './rotate.svg'
import IconSearch from './search.svg'
import IconSend from './send.svg'
import IconServices from './services.svg'
import IconSettingsSwitch from './settings-switch.svg'
import IconSettings from './settings.svg'
import IconShapesObjects from './shapes-objects.svg'
import IconShare from './share.svg'
import IconShieldedDocument from './shielded-document.svg'
import IconShoppingBag from './shopping-bag.svg'
import IconStarsLightSparkle from './stars-light-sparkle.svg'
import IconStop from './stop.svg'
import IconSupportChat from './support-chat.svg'
import IconSupport from './support.svg'
import IconTaxi from './taxi.svg'
import IconThreeDots from './three-dots.svg'
import IconTools from './tools.svg'
import IconTrash from './trash.svg'
import IconTurnover from './turnover.svg'
import IconUnlink from './unlink.svg'
import IconUserAdd from './user-add.svg'
import IconUser from './user.svg'
import IconUsers from './users.svg'
import IconVATRate from './vat-rate.svg'
import IconVisa from './visa.svg'
import IconWalletOut from './wallet-out.svg'

export interface IconsType {
  [key: string]: React.ComponentType
}

export {
  IconAirplane,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconBank,
  IconBankAdd,
  IconBankCheck,
  IconBankUp,
  IconBankingTicket,
  IconBarcodeScanner,
  IconBlank,
  IconBriefcase,
  IconCA,
  IconCalculator,
  IconCalendar,
  IconCalendarAdd,
  IconCalendarSchedule,
  IconCamera,
  IconCard,
  IconCardAdd,
  IconCardDeposit,
  IconCardWarning,
  IconCardWithdraw,
  IconCashDeposit,
  IconCashGuarantee,
  IconCashTransferIn,
  IconCashTransferOut,
  IconCashWithdraw,
  IconChart,
  IconCheck,
  IconCheckRounded,
  IconCheque,
  IconChevronLeft,
  IconChevronRight,
  IconCircle,
  IconClaimOnline,
  IconCoinStack,
  IconColorPicker,
  IconConsultFaqs,
  IconCopyText,
  IconCross,
  IconCrossRounded,
  IconCrossRoundedFilled,
  IconDanger,
  IconDocumentCalculator,
  IconDocumentEdit,
  IconDocumentFlash,
  IconDocumentText,
  IconDownloadArrow,
  IconEmail,
  IconEmailEdit,
  IconEmailRead,
  IconExpandMore,
  IconEye,
  IconEyeClosed,
  IconFlagFrance,
  IconFlagItaly,
  IconFlash,
  IconForkKnife,
  IconGasStation,
  IconGift,
  IconGmail,
  IconHospital,
  IconHouse,
  IconHousing,
  IconInformationSquare,
  IconInvoice,
  IconInvoiceAdd,
  IconJustice,
  IconLCL,
  IconLightBulb,
  IconLocker,
  IconLogOut,
  IconMaterialWarranty,
  IconMenu,
  IconMenuCloseLeft,
  IconMenuCloseRight,
  IconMinus,
  IconMobileDevice,
  IconNotification,
  IconOptions,
  IconOutlook,
  IconPen,
  IconPenEdit,
  IconPending,
  IconPhone,
  IconPieChart,
  IconPlus,
  IconPlusRounded,
  IconProtectionShield,
  IconQuestionCircle,
  IconQuestionSquare,
  IconRIB,
  IconRecurring,
  IconReload,
  IconRocket,
  IconRotate,
  IconSearch,
  IconSend,
  IconServices,
  IconSettings,
  IconSettingsSwitch,
  IconShapesObjects,
  IconShare,
  IconShieldedDocument,
  IconShoppingBag,
  IconStarsLightSparkle,
  IconStop,
  IconSupport,
  IconSupportChat,
  IconTaxi,
  IconThreeDots,
  IconTools,
  IconTrash,
  IconTurnover,
  IconUnlink,
  IconUser,
  IconUserAdd,
  IconUsers,
  IconVATRate,
  IconVisa,
  IconWalletOut,
}
