import Head from 'next/head'
import { PropsWithChildren } from 'react'

export const LayoutEmpty = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex min-h-screen flex-col bg-white">
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {children}
    </div>
  )
}
