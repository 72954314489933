import { EnumFeatureFlipping } from '@blank/api'
import { useFeatureFlippingContext } from '../contexts/FeatureFlippingContext'

export const useFeatureFlipping = () => {
  const { features, enableAll } = useFeatureFlippingContext()

  const isFeatureEnabled = (featureName: EnumFeatureFlipping) => {
    return enableAll || features?.includes(featureName)
  }

  return {
    canAccessSca: isFeatureEnabled(EnumFeatureFlipping.SCA),
    canAccessAdvancedTypeTransfer: isFeatureEnabled(
      EnumFeatureFlipping.WEB_ADVANCED_TYPE_TRANSFER
    ),
    canAccessSettings: isFeatureEnabled(EnumFeatureFlipping.WEB_PARAMETERS),
    canDisplayVersionNumber: isFeatureEnabled(
      EnumFeatureFlipping.APP_VERSION_NUMBER_VISIBLE
    ),
    canAccessAccountClosing: isFeatureEnabled(
      EnumFeatureFlipping.WEB_CLOSING_ACCOUNT
    ),
    allFeaturesEnabled: enableAll,
  }
}
