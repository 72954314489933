import { EnumBillingServiceId } from '@blank/api'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { useCountryExternalLinks } from './useCountryExternalLinks'

export const useSupportTouchpoints = () => {
  const { isLoading, services } = useCorporateOfferServices()
  const externalLinks = useCountryExternalLinks()
  const { supportPhoneNumber, canUseExtendedSupport } = useCountryConfig()

  const configSupportPhoneNumber = canUseExtendedSupport
    ? supportPhoneNumber
    : undefined
  const serviceSupportPhoneNumber = services?.find(
    (service) => service.id === EnumBillingServiceId.PHONE_SUPPORT
  )?.phone
  const phoneSupport = serviceSupportPhoneNumber ?? configSupportPhoneNumber

  const emailSupport = externalLinks.supportEmail

  const faqURL = externalLinks.faq

  return {
    phoneSupport,
    emailSupport,
    faqURL,
    isLoading,
  }
}
